import React from 'react'
import {Container, Row, Col} from 'reactstrap'
import Link from 'gatsby-link'

import { getLocale } from '../../locales'

import './Footer.scss'


export default class Footer extends React.Component {
    render () {
        const locale = getLocale(this.props.location)

        let footerLinks = this.props.footerLinks.slice(0, 4).map(
            edge => <Link key={edge.slug} to={`/${locale.shortCode}/${edge.slug}/`}>{edge.title}</Link>
        )

        let menuFooterLinks = this.props.footerLinks.slice(4).map(
            edge => <Link key={edge.slug} to={`/${locale.shortCode}/${edge.slug}/`}>{edge.title}</Link>
        )

        return <div className="page-footer d-md-block">
            <Container>
                <Row>
                    <Col sm="3" className="links">
                        {footerLinks}
                    </Col>

                    <Col sm="3" className="links">
                        {menuFooterLinks}
                    </Col>

                    <Col sm="6" className="general-info">
                        <div className="social-links">
                            <a
                                className="pinterest"
                                title="Refundor on Pinterest"
                                href="https://www.pinterest.com/refundor/"
                            >Refundor on Pinterest</a>

                            <a
                                className="instagram"
                                title="Refundor on Instagram"
                                href="https://www.instagram.com/refundor/"
                            >Refundor on Instagram</a>

                            <a
                                className="facebook"
                                title="Refundor on Facebook"
                                href="https://www.facebook.com/refundor/"
                            >Refundor on Facebook</a>

                            <a
                                className="twitter"
                                title="Refundor on Twitter"
                                href="https://twitter.com/refundor"
                            >Refundor on Twitter</a>
                        </div>

                        <div className="copyright">{this.props.copyright}</div>
                    </Col>
                </Row>
            </Container>
        </div>
    }
}

