import _ from 'lodash'
import React from 'react'
import {Helmet} from 'react-helmet'
import {StaticQuery, graphql} from 'gatsby'
import { Location } from '@reach/router'

import { getLocale } from '../../locales'
import Header from '../Header'
import Footer from '../Footer/Footer'


const TemplateWrapper = ({ children, data, className, pageContext }) => {
    return (
        <Location>
            {({ location }) => {
                const locale = getLocale(location)
                const content = _.find(
                    data.allContentfulHomepage.edges,
                    ({ node }) => node.node_locale === locale.code,
                ).node

                return (
                    <div className={className}>
                        <Helmet
                            title={content.seoTitle.seoTitle}
                            meta={[
                                { name: 'description', content: content.seoDescription.seoDescription },
                                { name: 'keywords', content: 'refunds, flight, cancelled, delayed' },

                                { property: 'og:title', content: content.seoTitle.seoTitle },
                                { property: 'og:description', content: content.seoDescription.seoDescription },
                                { property: 'og:image', content: 'https://refundor.com/refundor-og-banner.png'},

                                { name: 'p:domain_verify', content: "4bffd66793183ab3c345c9344e1048be"},
                                { name: 'facebook-domain-verification', content: "a7sgblfmwym4pd5kkcmpzcg82icyhq"},

                                { name: 'twitter:card', content: 'summary'},
                                { name: 'twitter:site', content: '@refundor'},
                            ]}
                        />

                        <Header headerLinks={content.headerLinks} location={location} pageContext={pageContext} />

                        {children}

                        <Footer footerLinks={content.footerLinks} copyright={content.copyright} location={location} />
                    </div>
                )
            }}
        </Location>
    )
}


export default props => <StaticQuery
    query={graphql`
        query LayoutContent {
            allContentfulHomepage {
              edges {
                node {
                    node_locale
                    seoTitle {
                        seoTitle
                    }
                    seoDescription {
                        seoDescription
                    }
                    headerLinks {
                        __typename
                        ... on ContentfulGatsbyPage {
                          slug
                          title
                        }
                        ... on ContentfulGenericPage {
                          slug
                          title
                        }
                    }
                    footerLinks {
                        __typename
                        ... on ContentfulGatsbyPage {
                          slug
                          title
                        }
                        ... on ContentfulGenericPage {
                          slug
                          title
                        }
                    }
                    copyright
                }
              }
            }
        }
    `}
    render={data => <TemplateWrapper {...props} data={data} />}
/>
