import _ from 'lodash'
import * as React from 'react'
import { navigate } from 'gatsby-link'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import {Location, LOCALES, getLocale, activateLocale} from '../../locales'

type Props = {
    isOpen: boolean
    onToggle: () => void
    location: Location
    localizedPaths?: { [key: string]: string }
}

const LocaleSwitcher = (props: Props) => {
    const locale = getLocale(props.location)

    if (LOCALES.length < 2) {
        return null
    }

    return (
        <Dropdown
            size="sm"
            isOpen={props.isOpen}
            toggle={props.onToggle}
        >
            <DropdownToggle className="border-0" caret outline color="primary">
                <span className={`flag-icon flag-icon-${locale.flagCode}`}></span>
                {locale.label}
            </DropdownToggle>
            <DropdownMenu>
                {LOCALES.filter(i => !i.hide).map(locale => (
                    <DropdownItem
                        key={locale.code}
                        size="sm"
                        onClick={() => {
                            const path = _.get(props.localizedPaths, locale.shortCode) || `/${locale.shortCode}`
                            navigate(path)
                            activateLocale({pathname: path})
                        }}
                    >
                        <span className={`flag-icon flag-icon-${locale.flagCode}`}></span>
                        {locale.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    )
}

export default LocaleSwitcher
