import React from 'react'
import Link from 'gatsby-link'
import {
    Container,
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    Collapse,
    NavbarToggler
} from 'reactstrap'

import LocaleSwitcher from './LocaleSwitcher'
import { getLocale } from '../../locales'

import LOGO_IMAGE from './logo.svg'

import 'flag-icon-css/css/flag-icons.css'
import './Header.scss'

const STYLE = {
    navbar: {
        background: 'white',
        height: '5rem',
    },
    logo: {
        width: '143px',
        height: '40px',
    },
}

export default class Header extends React.Component {
    constructor (props) {
        super(props)

        this.state = {
            isMenuOpen: false,
            isLocaleDropdownOpen: false,
        }
    }

    onToggleMenu() {
        this.setState({isMenuOpen: !this.state.isMenuOpen})
    }

    closeMenu() {
        this.setState({isMenuOpen: false})
    }

    onToggleLocaleSwitcher() {
        this.setState({isLocaleDropdownOpen: !this.state.isLocaleDropdownOpen})
    }

    render() {
        const locale = getLocale(this.props.location)

        return <Navbar expand="md" light className="header" container={false}>
            <Container>
                <div className="d-flex">
                    <NavbarBrand tag="span">
                        <Link to={`/${locale.shortCode}`}>
                            <img style={STYLE.logo} src={LOGO_IMAGE} alt="Refundor"/>
                        </Link>
                    </NavbarBrand>

                    <div className="d-flex flex-column justify-content-center">
                        <LocaleSwitcher
                            isOpen={this.state.isLocaleDropdownOpen}
                            onToggle={() => this.onToggleLocaleSwitcher()}
                            location={this.props.location}
                            localizedPaths={this.props.pageContext && this.props.pageContext.localizedPaths}
                        />
                    </div>
                </div>

                <NavbarToggler onClick={() => this.onToggleMenu()}/>

                <Collapse navbar isOpen={this.state.isMenuOpen}>
                    <Nav navbar className="ms-auto">
                        {this.props.headerLinks.map(({ slug, title }, index) => (
                            <NavItem key={slug}>
                                <a
                                    onClick={() => this.closeMenu()}
                                    className={[
                                        'nav-link',
                                        index === this.props.headerLinks.length - 1 ? 'claim-link' : '',
                                    ].join(' ')}
                                    href={`/${locale.shortCode}/${slug}`}
                                >
                                    {title}
                                </a>
                            </NavItem>
                        )

                        )}

                        {/* reactstrap's NavItem renders without extra classes, using plain div */}
                        <div className="nav-item social-links d-block d-md-none">
                            <a
                                className="pinterest"
                                title="Refundor on Pinterest"
                                href="https://www.pinterest.com/refundor/"
                            >Refundor on Pinterest</a>

                            <a
                                className="instagram"
                                title="Refundor on Instagram"
                                href="https://www.instagram.com/refundor/"
                            >Refundor on Instagram</a>

                            <a
                                className="facebook"
                                title="Refundor on Facebook"
                                href="https://www.facebook.com/refundor/"
                            >Refundor on Facebook</a>

                            <a
                                className="twitter"
                                title="Refundor on Twitter"
                                href="https://twitter.com/refundor"
                            >Refundor on Twitter</a>
                        </div>

                        {/* reactstrap's NavItem renders without extra classes, using plain div */}
                        <div className="nav-item copyright d-block d-md-none">
                            ©2018 Refundor. All rights reserved.
                        </div>
                    </Nav>
                </Collapse>
            </Container>
        </Navbar>
    }
}
