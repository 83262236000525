import { graphql } from 'gatsby'
import React from 'react'
import {Helmet} from 'react-helmet'
import {Container} from 'reactstrap'
import Layout from '../components/Layout/Layout'

import './GenericPage.scss'

export default class GenericPage extends React.Component {
    render () {
        let data = this.props.data.contentfulGenericPage

        return <Layout pageContext={this.props.pageContext}>
            <Container className='content-page'>
                <Helmet
                    title={data.seoTitle}
                />

                <div dangerouslySetInnerHTML={{__html: data.body.childMarkdownRemark.html}}/>
            </Container>
        </Layout>
    }
}


export const pageQuery = graphql`
    query GenericPageQuery($locale: String!, $slug: String!) {
      contentfulGenericPage(node_locale: {eq: $locale}, slug: {eq: $slug}) {
        title
        seoTitle
        body {
          childMarkdownRemark {
            html
          }
        }
      }
    }
`
